import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import StaticImage from "gatsby-plugin-image"


const FeasabilityStudy = ({data: {strapiFeasabilityStudy}}) => (
  <Layout>
   
    <SEO title="PETNECK2 Feasability Study" />

    <div class="content">
      <div class="title">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiFeasabilityStudy.content.Title}</ReactMarkdown></p>
      </div>
      <div class="col1">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiFeasabilityStudy.content.Column1}</ReactMarkdown></p>
      </div>
      <div class="col2">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiFeasabilityStudy.content.Column2}</ReactMarkdown></p>
      </div>
      <div class="col3">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiFeasabilityStudy.content.Bottom_text}</ReactMarkdown></p>
      </div>
    </div>

  </Layout>
)

export const aboutProgrammeQuesry = graphql`
  query FeasabilityStudyQuery {
    strapiFeasabilityStudy {
      content {
        Title
        Column1
        Column2
        Bottom_text
      }
      created_at
    }
  }
`

export default  FeasabilityStudy

